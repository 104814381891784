import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },

  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/orders/sales',
    name: 'SalesOrders',
    component: () => import('../views/order/SalesOrders.vue')
  },
  {
    path: '/order/add',
    name: 'CreateOrder',
    component: () => import('../views/order/CreateOrder.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Products
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/product/Products.vue')
  },
  {
    path: '/product/add',
    name: 'AddProduct',
    component: () => import('../views/product/AddProduct.vue')
  },
  {
    path: '/product/edit/:id',
    name: 'EditProduct',
    component: () => import('../views/product/EditProduct.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/product/Categories.vue')
  },
  {
    path: '/categorie/add',
    name: 'AddCategorie',
    component: () => import('../views/product/AddCategorie.vue')
  },
  {
    path: '/categorie/edit/:id',
    name: 'EditCategorie',
    component: () => import('../views/product/EditCategorie.vue')
  },
  {
    path: '/barcodes',
    name: 'Barcode',
    component: () => import('../views/product/Barcode.vue')
  },

  //Inventory
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/inventory/Inventory.vue')
  },
  {
    path: '/storehouses',
    name: 'Storehouses',
    component: () => import('../views/inventory/Storehouses.vue')
  },

  //Document
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/document/Documents.vue')
  },
  {
    path: '/document/add',
    name: 'CreateDocument',
    component: () => import('../views/document/CreateDocument.vue')
  },

  //Customers
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customer/Customers.vue')
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: () => import('../views/customer/Customer.vue')
  },

  //Promotions
  {
    path: '/promotions',
    name: 'Promotions',
    component: () => import('../views/promotion/Promotions.vue')
  },

  //Reports
  {
    path: '/report/inventory',
    name: 'InventoryReport',
    component: () => import('../views/report/InventoryReport.vue')
  },
  {
    path: '/report/date',
    name: 'Report',
    component: () => import('../views/report/DateReport.vue')
  },
  {
    path: '/report/popular',
    name: 'MorePopularReport',
    component: () => import('../views/report/MostPopular.vue')
  },
  {
    path: '/report/product',
    name: 'PerPrdouctReport',
    component: () => import('../views/report/ProductReport.vue')
  },

  //SNS
  {
    path: '/sns/facebook',
    name: 'Facebook',
    component: () => import('../views/sns/Facebook.vue')
  },

  //HR
  {
    path: '/staffs',
    name: 'Staffs',
    component: () => import('../views/hr/Employees.vue')
  },
  {
    path: '/staff/:id',
    name: 'Staff',
    component: () => import('../views/hr/Employee.vue')
  },
  {
    path: '/staff/add',
    name: 'AddStaff',
    component: () => import('../views/hr/AddEmployee.vue')
  },
  {
    path: '/shift',
    name: 'Schedule',
    component: () => import('../views/hr/Schedule.vue')
  },
  {
    path: '/payroll',
    name: 'Payroll',
    component: () => import('../views/hr/Payroll.vue')
  },

  //Settings
  {
    path: '/settings/website',
    name: 'WebsiteSettings',
    component: () => import('../views/setting/WebsiteSettings.vue')
  },
  {
    path: '/settings/account',
    name: 'AccountSettings',
    component: () => import('../views/setting/AccountSettings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
